import { Spinner } from 'components';
import React, { Suspense } from 'react';

export interface PageSuspenseProps {
  children: React.ReactNode;
  noSpinner?: boolean;
}

const PageSuspense: React.FC<PageSuspenseProps> = ({ children, noSpinner }) => (
  <Suspense fallback={noSpinner ? <></> : <Spinner />}>{children}</Suspense>
);

export default PageSuspense;
