import { SVGProps } from 'react';

const SvgSaturn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.276 3.52664C18.8012 0.41277 12.6782 0.48807 8.39943 4.07834C4.12071 7.66861 3.04829 13.631 5.42597 18.5046C1.92458 22.2792 0.0536656 25.2219 0.827704 26.1241C1.59476 27.0182 4.78437 25.7037 9.0615 22.9533C13.5703 26.4253 19.9893 26.4814 24.4257 22.7589C28.862 19.0363 29.8515 12.7637 27.1221 7.79869C30.5626 4.07315 32.3936 1.17494 31.6266 0.280842C30.8525 -0.621394 27.612 0.724889 23.276 3.52664ZM23.2899 21.435C19.6718 24.4709 14.4841 24.5278 10.6578 21.8944C13.0836 20.2384 15.7758 18.1961 18.5291 15.8858C21.2825 13.5754 23.7584 11.2811 25.8057 9.18386C27.8096 13.3463 26.9079 18.3991 23.2899 21.435ZM26.2388 6.41948C25.8774 6.84618 25.4646 7.30732 25.0054 7.79722C23.1511 9.77544 20.5443 12.2199 17.544 14.7375C14.5437 17.255 11.6804 19.4005 9.40484 20.8876C8.84129 21.2559 8.31398 21.5836 7.82942 21.8668C6.07528 22.8918 4.88313 23.3309 4.57904 22.9764C4.27204 22.6186 4.91883 21.5096 6.25267 19.9414C6.61964 19.51 7.0392 19.0435 7.50462 18.5486C7.21998 18.0779 6.97567 17.5923 6.77096 17.096C5.10121 13.0478 6.07718 8.30385 9.53519 5.40223C12.9932 2.50061 17.885 2.32096 21.6517 4.60962C22.1135 4.8902 22.5581 5.20767 22.9813 5.56209C23.5509 5.18854 24.0846 4.85515 24.5748 4.56734C26.3565 3.52123 27.5682 3.07074 27.8752 3.42859C28.1793 3.78305 27.5472 4.87479 26.2388 6.41948Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSaturn;
