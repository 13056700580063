import config from 'config';
import { request } from 'graphql-request';

import gql from 'clients/subgraph/gql';

export const getCorePoolParticipantsCount = () =>
  request(config.subgraphUrl, gql.CorePoolParticipantsCountDocument);

// export const getIsolatedPoolParticipantsCount = () =>
//   request(config.subgraphUrl, gql.CorePoolParticipantsCountDocument);
