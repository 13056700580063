import bnbLogo from 'packages/tokens/img/bnb.svg';
import ethLogo from 'packages/tokens/img/eth.svg';
import modeLogo from 'packages/tokens/img/mode.svg';
import { ChainId, ChainMetadata } from 'types';

export const CHAIN_METADATA: {
  [chainId in ChainId]: ChainMetadata;
} = {
  //FIXME: remove this from venus
  [ChainId.BSC_MAINNET]: {
    name: 'BSC',
    logoSrc: bnbLogo,
    explorerUrl: 'https://bscscan.com',
    blockTimeMs: 3000,
    blocksPerDay: 28800,
    corePoolComptrollerContractAddress: '0xfD36E2c2a6789Db23113685031d7F16329158384', // unitroller
  },
  [ChainId.BSC_TESTNET]: {
    name: 'BSC Testnet',
    logoSrc: bnbLogo,
    explorerUrl: 'https://testnet.bscscan.com',
    blockTimeMs: 3000,
    blocksPerDay: 28800,
    corePoolComptrollerContractAddress: '0x94d1820b2D1c7c7452A163983Dc888CEC546b77D',
  },
  [ChainId.ETHEREUM]: {
    name: 'Ethereum',
    logoSrc: ethLogo,
    explorerUrl: 'https://etherscan.io',
    blockTimeMs: 12000,
    blocksPerDay: 7200,
    corePoolComptrollerContractAddress: '', // TODO: add record (see VEN-2120)
  },
  [ChainId.SEPOLIA]: {
    name: 'Sepolia',
    logoSrc: ethLogo,
    explorerUrl: 'https://sepolia.etherscan.io',
    blockTimeMs: 12000,
    blocksPerDay: 7200,
    corePoolComptrollerContractAddress: '', // TODO: add record (see VEN-2120)
  },
  [ChainId.MODE_MAINNET]: {
    name: 'Mode',
    logoSrc: ethLogo,
    explorerUrl: 'https://explorer.mode.network',
    blockTimeMs: 2000,
    blocksPerDay: 43200,
    corePoolComptrollerContractAddress: '', //FIXME: add for production
  },
  [ChainId.MODE_TESTNET]: {
    name: 'Mode Testnet',
    logoSrc: modeLogo,
    explorerUrl: 'https://sepolia.explorer.mode.network',
    blockTimeMs: 2000,
    blocksPerDay: 43200,
    corePoolComptrollerContractAddress: '0x15e9baa34F6e7F3e446a5bDeB759707e05fA7163',
  },
};
