import localConfig from 'config';
import { ChainId } from 'types';
import { defineChain } from 'viem';
import { type Chain, bsc, bscTestnet, mainnet, modeTestnet, sepolia } from 'wagmi/chains';

export const modeMainnet = defineChain({
  id: 34443,
  name: 'Mode',
  network: 'mode',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://mainnet.mode.network'],
    },
    public: {
      http: ['https://mainnet.mode.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.mode.network/',
    },
  },
  // contracts: {
  //   multicall3: {
  //     // address: '0xBAba8373113Fb7a68f195deF18732e01aF8eDfCF',
  //     blockCreated: 3019007,
  //   },
  // },
  testnet: false,
});

const getSupportedChains = (): Chain[] => {
  if (localConfig.isOnTestnet) {
    return [bscTestnet, sepolia, modeTestnet];
  }

  if (localConfig.environment === 'preview') {
    return [bsc, mainnet, modeMainnet];
  }

  return [bsc, modeMainnet];
};

// export const governanceChain = localConfig.isOnTestnet ? bscTestnet : bsc;
export const governanceChain = localConfig.isOnTestnet ? modeTestnet : modeMainnet;

const supportedChains = getSupportedChains();
export const chains = supportedChains.filter(c => localConfig.rpcUrls[c.id as ChainId]?.http);

// export const defaultChain = chains[0];
export const defaultChain = chains[0];
