import * as React from 'react';
import { SVGProps } from 'react';

const SvgFee = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.996 21.998c5.52 0 9.996-4.477 9.996-9.999S17.517 2 11.996 2C6.476 2 2 6.477 2 11.999s4.475 9.999 9.996 9.999Z"
      fill="currentColor"
    />
    <path
      d="M9.366 11.648c-.7 0-1.27-.215-1.708-.644-.439-.439-.658-.99-.658-1.652 0-.672.22-1.232.658-1.68C8.097 7.224 8.666 7 9.366 7c.71 0 1.283.224 1.722.672.439.448.658 1.008.658 1.68 0 .663-.22 1.213-.658 1.652-.439.43-1.013.644-1.722.644Zm.14 4.83h-1.05L14.42 7.14h1.064l-5.978 9.338Zm6.706-.49c-.439.439-1.008.658-1.708.658s-1.27-.22-1.708-.658c-.439-.439-.658-.99-.658-1.652 0-.672.22-1.232.658-1.68.439-.448 1.008-.672 1.708-.672s1.27.224 1.708.672c.448.448.672 1.008.672 1.68 0 .663-.224 1.213-.672 1.652Zm-6.846-5.432c.327 0 .593-.107.798-.322.205-.224.308-.518.308-.882 0-.383-.103-.686-.308-.91a1.033 1.033 0 0 0-.798-.336c-.327 0-.593.112-.798.336-.205.224-.308.527-.308.91 0 .355.103.644.308.868.215.224.48.336.798.336Zm4.34 4.676c.205.215.471.322.798.322.327 0 .593-.107.798-.322.215-.224.322-.523.322-.896 0-.383-.107-.686-.322-.91a1.033 1.033 0 0 0-.798-.336c-.327 0-.593.112-.798.336-.205.224-.308.527-.308.91 0 .373.103.672.308.896Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFee;
