/* Automatically generated file, do not update manually */

/* Automatically generated file, do not update manually */
import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import abi from 'packages/contracts/generated/infos/abis/LegacyPoolComptroller.json';
import { LegacyPoolComptroller } from 'packages/contracts/generated/infos/contractTypes';
import { useChainId, useProvider, useSigner } from 'packages/wallet';
import { useMemo } from 'react';
import { ChainId } from 'types';

import { getUniqueContractAddress } from 'packages/contracts/utilities/getUniqueContractAddress';

interface GetLegacyPoolComptrollerContractAddressInput {
  chainId: ChainId;
}

export const getLegacyPoolComptrollerContractAddress = ({
  chainId,
}: GetLegacyPoolComptrollerContractAddressInput) =>
  getUniqueContractAddress({ name: 'LegacyPoolComptroller', chainId });

export const useGetLegacyPoolComptrollerContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getLegacyPoolComptrollerContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetLegacyPoolComptrollerContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getLegacyPoolComptrollerContract = ({
  chainId,
  signerOrProvider,
}: GetLegacyPoolComptrollerContractInput) => {
  const address = getLegacyPoolComptrollerContractAddress({ chainId });
  return address
    ? (new Contract(address, abi, signerOrProvider) as LegacyPoolComptroller)
    : undefined;
};

interface UseGetLegacyPoolComptrollerContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetLegacyPoolComptrollerContract = (
  input?: UseGetLegacyPoolComptrollerContractInput,
) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId: input?.chainId });
  const { signer } = useSigner({ chainId: input?.chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getLegacyPoolComptrollerContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};
