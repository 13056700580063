import { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M6.66669 17.0128C6.66669 15.2024 6.66669 14.2973 7.03264 13.5016C7.39858 12.706 8.08584 12.1169 9.46036 10.9387L10.7937 9.79588C13.2781 7.66638 14.5203 6.60162 16 6.60162C17.4797 6.60162 18.7219 7.66638 21.2064 9.79588L22.5397 10.9387C23.9142 12.1169 24.6015 12.706 24.9674 13.5016C25.3334 14.2973 25.3334 15.2024 25.3334 17.0128V22.6666C25.3334 25.1808 25.3334 26.4379 24.5523 27.2189C23.7713 28 22.5142 28 20 28H12C9.48586 28 8.22878 28 7.44774 27.2189C6.66669 26.4379 6.66669 25.1808 6.66669 22.6666V17.0128Z"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M19.3334 28V21C19.3334 20.4477 18.8856 20 18.3334 20H13.6667C13.1144 20 12.6667 20.4477 12.6667 21V28"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgDashboard;
