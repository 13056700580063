import { useChainId } from 'packages/wallet';
import { ChainId } from 'types';

const featureFlags = {
  // integratedSwap: [ChainId.BSC_TESTNET, ChainId.BSC_MAINNET],
  // prime: [ChainId.BSC_MAINNET, ChainId.BSC_TESTNET],
  // tusdMigrationWarning: [],
  // trxMigrationWarning: [],
  // sxpDisablingWarning: [],
  // bethUpdateWarning: [],
  corePoolRoute: [
    ChainId.BSC_MAINNET,
    ChainId.BSC_TESTNET,
    ChainId.MODE_MAINNET, //FIXME: this enable the core pool menu on specific chain
    ChainId.MODE_TESTNET,
  ],
  corePoolMarketRoute: [
    ChainId.BSC_MAINNET,
    ChainId.BSC_TESTNET,
    ChainId.MODE_MAINNET,
    ChainId.MODE_TESTNET,
  ],
  // convertVrtRoute: [],
  // historyRoute: [],
  // vaiRoute: [],
  // swapRoute: [],
  // createProposal: [],
  // voteProposal: [],
  // lunaUstWarning: [],
};

export type FeatureFlag = keyof typeof featureFlags;

export interface UseIsFeatureEnabled {
  name: FeatureFlag;
}

export const useIsFeatureEnabled = ({ name }: UseIsFeatureEnabled) => {
  const { chainId } = useChainId();
  return !!featureFlags[name]?.includes(chainId);
};
