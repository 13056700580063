/* Automatically generated file, do not update manually */
export default {
  VenusLens: {
    919: '0x7534D6F34E4a7740BC67381db7CcA3dA6120f9Fe',
  },
  PoolLens: {},
  PoolRegistry: {},
  LegacyPoolComptroller: {
    919: '0x15e9baa34F6e7F3e446a5bDeB759707e05fA7163',
  },
  VaiController: {},
  VaiVault: {},
  XvsVault: {},
  XvsStore: {},
  GovernorBravoDelegate: {},
  XvsVesting: {},
  VrtConverter: {},
  Maximillion: {
    919: '0x97078f135325689D1c6C49ed01F11c293cd9D881',
    34443: '',
  },
  XsequenceMulticall: {
    919: '',
    34443: '',
  },
  Multicall3: {
    919: '0xBAba8373113Fb7a68f195deF18732e01aF8eDfCF',
    34443: '',
  },
  ResilientOracle: {
    919: '0x9c34a261F15273F21c377ef2990BC0a9b5a96372',
    34443: '',
  },
  Prime: {},
  SwapRouter: {},
  Faucet: {
    919: '0xD778f6C33d0408117D955B198c8Ea92a030C50E9',
  },
};
