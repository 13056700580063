import { Button, Link, PrimaryButton } from 'components';
import config from 'config';
import { useChainId } from 'packages/wallet';
import { useLocation } from 'react-router';
import { ChainId } from 'types';

import { Subdirectory, routes } from 'constants/routing';

import { Breadcrumbs } from '../Breadcrumbs';
import { ChainSelect } from '../ChainSelect';
import ClaimRewardButton from '../ClaimRewardButton';
import ConnectButton from '../ConnectButton';

export const Header: React.FC = () => {
  const { chainId } = useChainId();
  const { pathname } = useLocation();
  return (
    <div className="px-4 pb-4 pt-6 md:flex md:justify-between md:px-6 md:py-8 xl:px-10">
      <div className="flex flex-1 items-center">
        <Breadcrumbs />
      </div>
      <div className="hidden md:flex md:h-12 md:items-center md:space-x-4 md:pl-6">
        {/* <ClaimRewardButton className="flex-none md:whitespace-nowrap" /> */}

        {config.environment !== 'mainnet' &&
          chainId &&
          chainId === ChainId.MODE_TESTNET &&
          pathname !== Subdirectory.FAUCET && (
            <div className="flex flex-1 items-center justify-center">
              <Link to={Subdirectory.FAUCET} target="_self">
                <PrimaryButton className="text-offWhite ">Get Faucet</PrimaryButton>
              </Link>
            </div>
          )}
        {config.environment !== 'mainnet' && <ChainSelect />}

        <ConnectButton className="flex-none" />
      </div>
    </div>
  );
};
