import { SVGProps } from 'react';

const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <circle
      cx="16"
      cy="9.33333"
      r="5.33333"
      stroke="#99A0B8"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M7.52923 22.9361C8.39102 20.1976 11.1051 18.6667 13.9759 18.6667H18.0241C20.895 18.6667 23.609 20.1976 24.4708 22.9361C24.8585 24.1679 25.1852 25.5685 25.2944 27.0009C25.3364 27.5516 24.8856 28 24.3334 28H7.66669C7.1144 28 6.66363 27.5516 6.70562 27.0009C6.81484 25.5685 7.14158 24.1679 7.52923 22.9361Z"
      stroke="#99A0B8"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export default SvgPerson;
